import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
    disableRipple: true,
  },
  styleOverrides: {
    sizeLarge: {
      padding: '12px 24px',
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
    sizeMedium: {
      padding: '8px 24px',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      '.MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
    sizeSmall: {
      padding: '4px 12px',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    containedPrimary: ({ theme }) => ({
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      border: `1px solid ${theme.palette.brand.purple.main}`,
      '&:hover': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.brand.purple.main,
      },
      '&.Mui-focus, &.Mui-focusVisible': {
        backgroundColor: theme.palette.brand.purple.main,
        boxShadow: `0px 0px 0px 1px ${theme.palette.application.background.contrastText}, 0px 0px 0px 3px ${theme.palette.brand.purple.main}`,
        WebkitBoxShadow: `0px 0px 0px 1px ${theme.palette.application.background.contrastText}, 0px 0px 0px 3px ${theme.palette.brand.purple.main}`,
      },
      '&:active': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.application.cream.main,
        border: `1px solid ${theme.palette.application.purple.main}`,
        color: theme.palette.application.purple.main,
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.neutral['04'],
        border: `1px solid ${theme.palette.neutral['04']}`,
        color: theme.palette.neutral['05'],
      },
    }),
    containedSecondary: ({ theme }) => ({
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      color: 'white',
      backgroundColor: theme.palette.application.purple[75],
      '&:hover': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.application.purple[75],
      },
      '&.Mui-focus, &.Mui-focusVisible': {
        backgroundColor: theme.palette.application.purple[75],
        boxShadow: `0px 0px 0px 1px ${theme.palette.application.background.contrastText}, 0px 0px 0px 3px ${theme.palette.application.purple[75]}`,
        WebkitBoxShadow: `0px 0px 0px 1px ${theme.palette.application.background.contrastText}, 0px 0px 0px 3px ${theme.palette.application.purple[75]}`,
      },
      '&:active': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.application.cream.main,
        border: `1px solid ${theme.palette.application.purple.main}`,
        color: theme.palette.application.purple.main,
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.neutral['04'],
        border: `1px solid ${theme.palette.neutral['04']}`,
        color: theme.palette.neutral['05'],
      },
    }),
    outlinedPrimary: ({ theme }) => ({}),
    outlinedSecondary: ({ theme }) => ({
      color: theme.palette.application.purple[75],
      border: `1px solid ${theme.palette.application.purple[75]}`,
      backgroundColor: theme.palette.application.background.main,
      '&:hover': {
        color: theme.palette.application.purple.main,
        border: `1px solid ${theme.palette.application.purple.main}`,
        backgroundColor: theme.palette.brand.purple[25],
      },
      '&.Mui-focus, &.Mui-focusVisible': {
        color: theme.palette.application.purple.main,
        backgroundColor: theme.palette.application.purple[25],
        boxShadow: `0px 0px 0px 3px ${theme.palette.application.purple.main}, 0px 0px 0px 1px ${theme.palette.application.background.contrastText}`,
        WebkitBoxShadow: `0px 0px 0px 3px ${theme.palette.application.purple.main}, 0px 0px 0px 1px ${theme.palette.application.background.contrastText}`,
      },
      '&:active': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.application.background.light,
        border: `1px solid ${theme.palette.application.purple.main}`,
        color: theme.palette.application.purple.main,
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        backgroundColor: theme.palette.neutral['03'],
        border: `1px solid ${theme.palette.neutral['03']}`,
        color: theme.palette.neutral['05'],
      },
    }),
    textPrimary: ({ theme }) => ({
      border: '1px solid transparent',
      '&:hover': {
        backgroundColor: theme.palette.application.background.main,
      },
      '&.Mui-focus, &.Mui-focusVisible': {
        backgroundColor: theme.palette.application.background.main,
        border: `1px solid ${theme.palette.application.purple.main}`,
      },
      '&:active': {
        backgroundColor: theme.palette.neutral['01'],
        border: `1px solid ${theme.palette.application.purple.main}`,
        color: theme.palette.application.purple.main,
      },
      '&.Mui-disabled': {
        color: theme.palette.neutral['05'],
      },
    }),
    textSecondary: ({ theme }) => ({
      border: '1px solid transparent',
      color: theme.palette.application.purple[75],
      '&:hover': {
        backgroundColor: theme.palette.application.background.main,
      },
      '&.Mui-focus, &.Mui-focusVisible': {
        backgroundColor: theme.palette.application.background.main,
        border: `1px solid ${theme.palette.application.purple[75]}`,
      },
      '&:active': {
        backgroundColor: theme.palette.neutral['01'],
        border: `1px solid ${theme.palette.application.purple[75]}`,
        color: theme.palette.application.purple[75],
      },
      '&.Mui-disabled': {
        color: theme.palette.neutral['05'],
      },
    }),
  },
};
