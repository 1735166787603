import { FormControl, FormHelperText, Input, InputLabel, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

// TODO Support more mask props. Ref: https://imask.js.org/
export type FormTextFieldProps = {
  charLimit?: number;
  name: string;
  maskProps?: {
    mask: Array<string | RegExp>;
    // mask: string | Array<string | RegExp>;
    // blocks?: any;
    // lazy?: boolean;
    // overwrite?: boolean;
    // mask: string | RegExp | Array<string | RegExp>;
    // maskPlaceholder?: string;
    // alwaysShowMask?: boolean;
    // definitions?: Object;
  };
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref: any) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      lazy={false}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const FormTextField = (props: FormTextFieldProps & TextFieldProps) => {
  const { maskProps, charLimit, ...restProps } = props;

  return (
    <Controller
      name={restProps.name}
      render={({ field, fieldState }) => {
        const sanitizedValue = field.value === undefined || field.value === null ? '' : field.value.toString();

        const { onChange: fieldOnChange, ...restField } = field;
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          if (props.type === 'number') {
            return fieldOnChange(event.target.value === '' ? null : parseFloat(event.target.value.trimStart()));
          }

          let value = event.target.value;
          if (charLimit !== undefined) {
            value = value.slice(0, charLimit);
          }
          return fieldOnChange(value);
        };

        const onBlur = () => {
          if (sanitizedValue === '') {
            fieldOnChange(null);
          } else {
            fieldOnChange(sanitizedValue.trim());
          }
        };

        if (maskProps) {
          return (
            <FormControl variant='standard'>
              <InputLabel error={fieldState.invalid} required={restProps.required} htmlFor={restProps.id}>
                {restProps.label}
              </InputLabel>
              <Input
                {...restField}
                value={sanitizedValue}
                onChange={onChange}
                onBlur={onBlur}
                name={restProps.name}
                id={restProps.id}
                placeholder={restProps.placeholder}
                disabled={restProps.disabled}
                error={fieldState.invalid}
                inputComponent={TextMaskCustom as any}
                inputProps={{
                  ...maskProps,
                  // ...restProps.InputProps,
                  ...(restProps.label ? {} : { sx: { marginTop: '0 !important' } }),
                }}
                data-testid={restProps.name}
              />
              <FormHelperText disabled={fieldState.invalid} error={fieldState.invalid}>
                {fieldState.error?.message
                  ? fieldState.error?.message
                  : !!charLimit && `${charLimit - sanitizedValue.length} characters left.`}
              </FormHelperText>
            </FormControl>
          );
        }

        return (
          <TextField
            {...restProps}
            {...field}
            onChange={onChange}
            onBlur={onBlur}
            value={sanitizedValue}
            error={fieldState.invalid}
            helperText={
              fieldState.error?.message
                ? fieldState.error?.message
                : !!charLimit && `${charLimit - sanitizedValue.length} characters left.`
            }
            InputProps={{
              ...restProps.InputProps,
              ...(restProps.label ? {} : { sx: { marginTop: '0 !important' } }),
            }}
            inputProps={{
              'data-testid': restProps.name,
            }}
          />
        );
      }}
    />
  );
};
