import { Components, Theme } from '@mui/material';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    filledSoft: true;
    withDot: true;
  }
}

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
  variants: [
    {
      props: { variant: 'filledSoft' },
      style: {},
    },
    {
      props: { variant: 'withDot' },
      style: {},
    },
  ],
  styleOverrides: {
    root: ({ theme, ownerState: { color, variant } }) => ({
      padding: 0,
      height: 'fit-content',
      ...(!color && {
        backgroundColor: theme.palette.neutral['03'],
        color: theme.palette.neutral['09'],
      }),
      ...(variant === 'filledSoft' &&
        color === 'success' && {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.dark,
        }),
      ...(variant === 'filledSoft' &&
        color === 'info' && {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.dark,
        }),
      ...(variant === 'filledSoft' &&
        color === 'warning' && {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        }),
      ...(variant === 'filledSoft' &&
        color === 'error' && {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.dark,
        }),
      ...(variant === 'filledSoft' &&
        color === 'primary' && {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,
        }),
      ...(variant === 'filledSoft' &&
        color === 'secondary' && {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.secondary.dark,
        }),
      ...(variant === 'withDot' && {
        backgroundColor: theme.palette.neutral['03'],
        color: theme.palette.neutral[10],
        '&::before': {
          content: '""',
          width: '12px',
          height: '12px',
          marginLeft: '8px',
          marginRight: '-8px',
          backgroundColor:
            color === 'info'
              ? theme.palette.feedback.info01.dark
              : color === 'error'
              ? theme.palette.feedback.error.dark
              : color === 'warning'
              ? theme.palette.warning.dark
              : theme.palette.success.main,
          borderRadius: '20px',
        },
      }),
    }),
    sizeMedium: ({ theme }) => ({
      borderRadius: '4px',
    }),
    labelMedium: ({ theme }) => ({
      padding: '0.25rem 1rem',
      fontSize: '.875rem',
      lineHeight: '1.125rem',
      fontWeight: theme.typography.fontWeightRegular,
    }),
    sizeSmall: ({ theme }) => ({
      borderRadius: '20px',
    }),
    labelSmall: ({ theme }) => ({
      padding: '0.125rem 0.75rem',
      fontSize: '.625rem',
      lineHeight: '0.75rem',
      fontWeight: 600,
    }),
  },
};
