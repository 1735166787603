import { useDocumentTitle } from '@/navigation';
import { CssBaseline } from '@mui/material';
import { Outlet, useMatches } from 'react-router-dom';

export const AppContainer = () => {
  const matches = useMatches();
  useDocumentTitle(matches);

  return (
    <CssBaseline>
      <Outlet />
    </CssBaseline>
  );
};
