import sidePanelBg from '@/assets/images/side-panel-bg.png';
import logoWithTagline from '@/assets/logos/wellnecity-white-logo-tagline-2.png';
import { useServices } from '@/core/services';
import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router';

const SidePanel = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${sidePanelBg})`,
        backgroundSize: 'cover',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pt: '240px',
      }}
      data-testid='wellnecity-logo'
    >
      <Box>
        <img src={logoWithTagline} alt='Wellnecity Logo' style={{ height: 160, width: 'auto' }} />
      </Box>
    </Box>
  );
};

const ContentPanel = (props: { children: React.ReactNode }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
      <Box width={{ xs: '100%', md: '50%' }} maxWidth={520} mx={{ xs: 4, sm: 'auto' }} data-testid='external-content'>
        {props.children}
      </Box>
    </Box>
  );
};

const footerTextProps = {
  variant: 'body1' as 'body1',
  fontWeight: 'fontWeightLight',
  color: 'application.purple.main',
};

const Footer = () => {
  const { config } = useServices();

  return (
    <Stack py={3} spacing={1} alignItems='center'>
      <Typography {...footerTextProps}>
        Powered by{' '}
        <Typography component='span' fontWeight='fontWeightBold'>
          Wellnecity
        </Typography>
      </Typography>
      <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
        <Link {...footerTextProps} underline='none' href={config.urls.termsOfUse} data-testid='terms-of-use-button'>
          Terms of Use
        </Link>
        <Link
          {...footerTextProps}
          underline='none'
          href={config.urls.privacyPolicy}
          data-testid='privacy-policy-button'
        >
          Privacy Policy
        </Link>
        <Link {...footerTextProps} underline='none' href={config.urls.contact} data-testid='contact-button'>
          Contact
        </Link>
      </Stack>
    </Stack>
  );
};

export const ExternalLayout = () => {
  const sidePanelColWidth = 5;

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item md={sidePanelColWidth} display={{ xs: 'none', md: 'flex' }}>
        <SidePanel />
      </Grid>
      <Grid item xs={12} md={12 - sidePanelColWidth} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1 }}>
          <ContentPanel>
            <Outlet />
          </ContentPanel>
        </Box>
        <Footer />
      </Grid>
    </Grid>
  );
};
