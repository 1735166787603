import { Components, Theme } from '@mui/material';

export const MuiTab: Components<Omit<Theme, 'components'>>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'capitalize',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.neutral['08'],
      padding: '0.75rem 1.5rem',
      '&.Mui-selected': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.neutral['08'],
      },
    }),
  },
};
