import { useServices } from '@/core/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { authApi: api } = useServices();
  const logoutMutation = useMutation({
    mutationKey: ['logout'],
    mutationFn: () => api.logout(),
    onSettled: () => {
      localStorage.removeItem('token');
      queryClient.clear();
    },
  });
  return { ...logoutMutation, logout: logoutMutation.mutate };
};
