import { Components, Theme } from '@mui/material';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    warning: true;
  }
}

export const MuiCard: Components<Omit<Theme, 'components'>>['MuiCard'] = {
  defaultProps: {
    variant: 'elevation',
  },
  variants: [
    {
      props: { variant: 'elevation' },
      style: ({ theme }) => ({
        border: '1px solid white',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
        '&:hover': {
          border: `1px solid ${theme.palette.neutral['04']}`,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        },
      }),
    },
    {
      props: { variant: 'warning' },
      style: ({ theme }) => ({
        border: `2px solid ${theme.palette.brand.orange.main}`,
        margin: '-1px',
        '&:hover': {
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
        },
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.neutral['05']}`,
        '&:hover': {
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '4px',
    }),
  },
};
