import { Components, Theme } from '@mui/material';

export const MuiInput: Components<Omit<Theme, 'components'>>['MuiInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: '1em !important',
      paddingLeft: '4px',
      paddingTop: '2px',
      '&.Mui-focused': {
        '&:before, &:after': {
          borderColor: theme.palette.brand.purple.main,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['03'],
        '&:before, &:after': {
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.neutral['04'],
        },
      },
    }),
    underline: ({ theme }) => ({
      '&:before, &:after, &:hover:not(.Mui-disabled, .Mui-error):before': {
        borderColor: theme.palette.application.body.main,
      },
    }),
    input: ({ theme }) => ({
      padding: '2px 0 8px',
      '&::placeholder': {
        color: theme.palette.neutral['05'],
      },
    }),
  },
};
