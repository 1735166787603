import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Omit<Theme, 'components'>>['MuiLink'] = {
  defaultProps: {
    underline: 'none',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      cursor: 'pointer',
      color: theme.palette.application.link.main,
      '&:hover': {
        color: theme.palette.application.link.main,
      },
    }),
  },
};
