import { useEffect, useRef } from 'react';
import { PathMatch } from './types';
import { getTitle } from './utils';

export const useDocumentTitle = (matches: PathMatch[], prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const match = matches[matches.length - 1];

  const foundTitle = getTitle(match);
  const title = foundTitle ? `Wellnecity | ${foundTitle}` : 'Wellnecity';

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount]
  );
};
