import { Components, Theme } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const MuiAlert: Components<Omit<Theme, 'components'>>['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <HighlightOffIcon />,
    },
  },
  styleOverrides: {
    root: () => ({
      alignSelf: 'stretch',
      borderRadius: '2px',
      padding: '8px 16px',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 400,
      alignItems: 'center',
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.feedback.error.light,
      color: theme.palette.feedback.error.dark,
      '.MuiAlert-icon': { color: theme.palette.feedback.error.dark },
    }),
    icon: {
      padding: 0,
    },
    message: {
      padding: 0,
    },
  },
};
