import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';

type FormButtonProps = {
  isLoading?: boolean;
};

export const FormButton = (props: MuiButtonProps & FormButtonProps) => {
  const { isLoading, ...rest } = props;

  return (
    <MuiButton {...rest}>
      {isLoading && <CircularProgress size={20} sx={{ mr: 1 }} />} {props.children}
    </MuiButton>
  );
};
