export enum Env {
  Local = 'local',
  Dev = 'dev',
  Uat = 'uat',
  Prod = 'prod',
}

export interface Config {
  env: Env;
  authUrl: string;
  pmiUrl: string;
  pmmUrl: string;
  authApi: {
    baseUrl: string;
    timeout: number;
    useMock: boolean;
  };
  urls: {
    termsOfUse: string;
    privacyPolicy: string;
    contact: string;
  };
  sentry: {
    enabled: boolean;
    dsn: string;
  };
  session: {
    inactivityTimeoutMinutes: number;
    inactivityWarningDurationMinutes: number;
  };
}
export type ApiConfig = Config['authApi'];
export type SentryConfig = Config['sentry'];
