import { useResetPassword } from '@/core/hooks';
import { useGetPath } from '@/navigation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { FormButton, FormTextField } from '@wnc/ui/src/components/form';
import Yup from '@wnc/ui/src/utils';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

const resetPasswordFormSchema = Yup.object({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special case character'
    )
    .required()
    .label('New Password'),
  cPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required()
    .label('Repeat Password'),
});

type ResetPasswordFormValues = Yup.InferType<typeof resetPasswordFormSchema>;

const defaultValues: ResetPasswordFormValues = {
  newPassword: '',
  cPassword: '',
};

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();
  const [searchParams] = useSearchParams();
  const forgotPasswordToken = searchParams.get('token') || '';

  const { resetPassword, isLoading, isSuccess, isError, error } = useResetPassword();

  const formMethods = useForm<ResetPasswordFormValues>({
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = (data) => {
    resetPassword({ ...data, forgotPasswordToken });
  };

  const onGoToLogin = () => {
    navigate(getPath('Login'));
  };

  if (isSuccess) {
    return <Navigate to={getPath('Login')} />;
  }

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} data-testid='reset-password-form'>
        <Stack spacing={3} alignItems='center'>
          <Typography variant='h2'>Reset Password</Typography>
          {isError && (
            <Alert severity='error' data-testid='error-message'>
              {error?.message}
            </Alert>
          )}
          <FormTextField
            name='newPassword'
            label='New Password'
            placeholder='New Password'
            required
            type='password'
            autoComplete='password'
          />
          <FormTextField
            name='cPassword'
            label='Repeat Password'
            placeholder='Repeat Password'
            required
            type='password'
          />
        </Stack>
        <Stack spacing={1.5} mt={4} alignItems='center'>
          <FormButton
            fullWidth
            type='submit'
            disabled={isLoading}
            isLoading={isLoading}
            data-testid='reset-password-button'
          >
            Reset Password
          </FormButton>
          <Button variant='text' size='small' onClick={onGoToLogin} data-testid='back-to-login-button'>
            Back to Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
