import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.brand.purple[25],
      borderColor: theme.palette.application.purple.main,
      color: theme.palette.application.purple.main,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.brand.purple.main,
        color: 'white',
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.brand.purple.main,
        color: 'white',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
          backgroundColor: theme.palette.brand.purple.main,
          color: 'white',
        },
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['03'],
        color: theme.palette.application.purple[50],
      },
    }),
  },
};
