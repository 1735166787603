import { QueryClient } from '@tanstack/react-query';
import AuthApiClient from './api/pmi-api-client';
import { config } from './config';
import { SentryService } from './sentry/sentry';
import { Services } from './types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // notifyOnChangeProps: 'tracked',
    },
  },
});

const sentry = new SentryService(config);

export const services: Services = {
  config,
  queryClient,
  authApi: new AuthApiClient(config.authApi, queryClient, sentry),
  sentry,
};
