import { ContextType, PropsWithChildren, createContext, useContext } from 'react';
import { Services } from './types';

const ServicesContext = createContext<Services>({} as Services);

interface ServicesProviderProps extends PropsWithChildren {
  services: Services;
}

export const ServicesProvider: React.FC<ServicesProviderProps> = ({ services, ...rest }: ServicesProviderProps) => {
  return <ServicesContext.Provider value={services} {...rest} />;
};

export const useServices = (): ContextType<typeof ServicesContext> => {
  const context = useContext(ServicesContext);

  if (!context) {
    throw new Error('ServicesProvider missing');
  }

  return context;
};
