import { Components, Theme } from '@mui/material';

export const MuiLinearProgress: Components<Omit<Theme, 'components'>>['MuiLinearProgress'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      colorPrimary: theme.palette.application.purple,
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    }),
  },
};
