import { RouterProvider } from '@/navigation';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@wnc/ui/src/theme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ServicesProvider, services } from './core/services';

dayjs.extend(utc);

function App() {
  return (
    <ThemeProvider>
      <ServicesProvider services={services}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={services.queryClient}>
            <RouterProvider />
            <ReactQueryDevtools initialIsOpen={true} />
          </QueryClientProvider>
        </LocalizationProvider>
      </ServicesProvider>
    </ThemeProvider>
  );
}

export default App;
