import { merge } from 'lodash';
import { Config, Env } from './types';

export const defaultConfig: Config = {
  env: Env.Local,
  authUrl: 'http://localhost:3000/auth',
  pmiUrl: 'http://localhost:3001/pmi',
  pmmUrl: 'http://localhost:3002/pmm',
  authApi: {
    baseUrl: 'https://pmi-api.wncty.dev',
    timeout: 60000,
    useMock: true,
  },
  urls: {
    termsOfUse: 'https://wellnecity.com/terms-of-use/',
    privacyPolicy: 'https://wellnecity.com/privacy-policy/',
    contact: 'https://wellnecity.com/contact/',
  },
  sentry: {
    enabled: false,
    dsn: 'https://5ce7ca70eb2799bbdac7211b7905dfa7@o4506100468350976.ingest.sentry.io/4506107035189248',
  },
  session: {
    inactivityTimeoutMinutes: 60,
    inactivityWarningDurationMinutes: 1,
  },
};

const env = process.env.REACT_APP_ENV;
const envConfig = env ? (require(`./config.${env}`).config as Config) : {};
export const config = merge(defaultConfig, envConfig);
