import { AxiosRequestConfig } from 'axios';
import { ApiErrorResponse, ApiLoginResponse, ApiResponse, LoginRequest } from '../types';
import { identities } from './mock-data';
import { MockFn } from './types';

export const loginMock: MockFn<ApiErrorResponse | ApiLoginResponse> = (config: AxiosRequestConfig) => {
  const { email, password } = JSON.parse(config.data) as LoginRequest;
  const foundIdentity = identities.find(
    (identity) => identity.email_address === email && identity.internal.password === password
  );

  if (foundIdentity) {
    return [
      200,
      {
        token: foundIdentity.internal.token,
        data: {
          organizations: foundIdentity.organizations,
        },
      },
    ];
  } else {
    return [
      401,
      {
        err_message: 'Invalid email or password.',
      },
    ];
  }
};

export const logoutMock: MockFn<ApiResponse<{}>> = () => {
  return [200, { data: {} }];
};

export const forgotPasswordMock: MockFn<ApiResponse<{}>> = () => {
  return [200, { data: {} }];
};

export const resetPasswordMock: MockFn<ApiResponse<{}>> = () => {
  return [200, { data: {} }];
};
