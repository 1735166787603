import { useServices } from '@/core/services';
import { ForgotPasswordPayload } from '@/core/services/api/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useForgotPassword = () => {
  const { authApi: api } = useServices();

  const forgotPasswordMutation = useMutation<{}, AxiosError, ForgotPasswordPayload>({
    mutationKey: ['forgot-password'],
    mutationFn: (data: ForgotPasswordPayload) =>
      api.forgotPassword(data).then((res) => {
        return res.data;
      }),
    // onSuccess: (data) => {},
  });

  return { ...forgotPasswordMutation, forgotPassword: forgotPasswordMutation.mutate };
};
