import { ExternalLayout } from '@/components/external-layout';
import { ForgotPassword, Login, ResetPassword } from '@/pages';
import { CustomRouteObject } from '../types';

export const externalRoutes: CustomRouteObject[] = [
  {
    id: 'Login',
    path: 'login',
    element: <ExternalLayout />,
    children: [
      {
        id: 'LoginIndex',
        index: true,
        element: <Login />,
        title: 'Log In',
      },
    ],
  },
  {
    id: 'ForgotPassword',
    path: 'forgot-password',
    element: <ExternalLayout />,
    children: [
      {
        id: 'ForgotPasswordIndex',
        index: true,
        element: <ForgotPassword />,
        title: 'Forgot Password',
      },
    ],
  },
  {
    id: 'ResetPassword',
    path: 'reset-password',
    element: <ExternalLayout />,
    children: [
      {
        id: 'ResetPasswordIndex',
        index: true,
        element: <ResetPassword />,
        title: 'Reset Password',
      },
    ],
  },
  // {
  //   id: 'ExternalDemo',
  //   path: 'demo',
  //   element: <ExternalDemoLayout />,
  //   children: [
  //     {
  //       id: 'RichTextEditorDemoIndex',
  //       index: true,
  //       path: 'rich-text-editor',
  //       element: <RichTextEditorPage />,
  //       title: 'Rich Text Editor',
  //     },
  //   ],
  // },
];
