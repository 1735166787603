import { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Omit<Theme, 'components'>>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: '0.75rem 0.5rem',
      '.MuiButtonBase-root': {
        padding: '0 !important',
      },
    }),
    head: ({ theme }) => ({
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: theme.typography.fontWeightBold,
      borderBottomColor: theme.palette.neutral['06'],
    }),
    body: ({ theme }) => ({
      fontSize: '0.8125rem',
      lineHeight: '0.9rem',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.neutral['08'],
      borderBottomColor: theme.palette.neutral['05'],
    }),
  },
};
