import { useServices } from '@/core/services';
import { ApiLoginResponse, LoginRequest } from '@/core/services/api/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useLogin = () => {
  const { authApi: api } = useServices();

  const loginMutation = useMutation<ApiLoginResponse, AxiosError, LoginRequest>({
    mutationKey: ['token'],
    mutationFn: (data: LoginRequest) =>
      api.login(data).then((res) => {
        return res.data;
      }),
    onSuccess: (data) => {
      const { token } = data;
      localStorage.setItem('token', token);
    },
  });

  return { ...loginMutation, login: loginMutation.mutate };
};
