import * as yup from 'yup';
import 'yup-phone-lite';

yup.setLocale({
  mixed: {
    required: (params) => `${params.label} is required.`,
  },
  string: {
    email: (params) => `${params.label} is invalid.`,
  },
});

export default yup;
