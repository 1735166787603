import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Omit<Theme, 'components'>>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      lineHeight: '1em',
      fontWeight: theme.typography.fontWeightBold,
      transform: 'translate(0, 0) scale(0.75)',
      '&.Mui-focused:not(.Mui-error)': {
        color: 'brand.purple.main',
      },
      '&.Mui-disabled': {
        color: `${theme.palette.neutral[10]} !important`,
      },
    }),
  },
};
