import { Identity } from '@/model';
import { chc, wellnecity } from './organization';

export interface IdentityMock extends Identity {
  internal: {
    password: string;
    token: string;
    type: 'Offline' | 'Live';
  };
}

export const identities: IdentityMock[] = [
  {
    id: 'user-001',
    email_address: 'user1@test.com',
    first_name: 'Andrew',
    last_name: 'Fox',
    pictureUrl: 'https://randomuser.me/api/portraits/men/51.jpg',
    organizations: {
      chc: chc,
      wellnecity: wellnecity,
    },
    internal: {
      password: 'user1',
      token: 'user-001-token',
      type: 'Offline',
    },
  },
  {
    id: '64d35aed47c3e5b08cbd07af',
    email_address: 't.tham@wellnecity.com',
    first_name: 'Tom',
    last_name: 'Tham',
    organizations: {
      chc: chc,
    },
    internal: {
      password: 'Admin@123',
      token: '64d35aed47c3e5b08cbd07af-token',
      type: 'Live',
    },
  },
];
