import { Components, Theme } from '@mui/material';

export const MuiTextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
  defaultProps: {
    variant: 'standard',
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: ({ ownerState: { multiline, label } }) => ({
      alignSelf: 'stretch',
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&.Mui-disabled': {
        borderBottom: '1px solid',
        borderColor: 'neutral[04]',
      },
      ...(multiline && label
        ? {
            marginTop: '24px',
            '.MuiInputLabel-root': {
              marginTop: '-24px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              legend: {
                display: 'none',
              },
            },
          }
        : {}),
    }),
  },
};
