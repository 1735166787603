import { Components, Theme } from '@mui/material';

export const MuiListItemText: Components<Omit<Theme, 'components'>>['MuiListItemText'] = {
  styleOverrides: {
    primary: ({ theme }) => ({
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.application.purple.main,
    }),
  },
};
