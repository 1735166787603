import { Components, Theme } from '@mui/material';

export const MuiAccordion: Components<Omit<Theme, 'components'>>['MuiAccordion'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: 'none',
      border: `1px solid ${theme.palette.neutral['04']}`,
      borderRadius: '4px',
      '&:before': {
        content: 'none',
      },
      '&.Mui-expanded': {
        borderColor: theme.palette.application.purple.main,
      },
      '&.MuiAccordionSummary-expandIconWrapper': {
        border: `1px solid ${theme.palette.application.purple.main}`,
      },
    }),
  },
};
