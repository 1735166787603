import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface DebugVersionProps {
  env: string;
  buildTimeStamp: string | undefined;
  version: string | undefined;
  gitCommitHash: string | undefined;
}

export const DebugVersion = (props: DebugVersionProps) => {
  const { env, buildTimeStamp, version, gitCommitHash } = props;

  return (
    <Stack justifyContent='center' sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant='body2' fontWeight='fontWeightMedium' data-testid='version-label'>
        {env !== 'prod' ? env.toUpperCase() + ' ' : ''}v{dayjs(buildTimeStamp).format('MM.DD.YYYY')}
      </Typography>
      {env !== 'prod' && (
        <Typography variant='body3' data-testid='version-value'>
          Release {version} {gitCommitHash}
        </Typography>
      )}
    </Stack>
  );
};
