import { Components, Theme } from '@mui/material';

export const MuiDrawer: Components<Omit<Theme, 'components'>>['MuiDrawer'] = {
  styleOverrides: {
    paperAnchorLeft: {
      boxShadow: '3px 0px 4px rgba(0, 0, 0, 0.1)',
    },
    paperAnchorRight: {
      boxShadow: '-3px 0px 4px rgba(0, 0, 0, 0.1)',
    },
  },
};
