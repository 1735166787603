import { Components, Theme } from '@mui/material';

export const MuiFormLabel: Components<Omit<Theme, 'components'>>['MuiFormLabel'] = {
  styleOverrides: {
    asterisk: ({ theme }) => ({
      color: theme.palette.feedback.error.main,
      '&$error': {
        color: theme.palette.feedback.error.main,
      },
    }),
  },
};
