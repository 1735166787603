import { useLogout } from '@/core/hooks';
import { useGetPath } from '@/navigation';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ErrorPageProps {
  error: any;
  resetError?: () => void;
}

export function ErrorPage(props: ErrorPageProps) {
  const theme = useTheme();
  const { error, resetError } = props;
  const navigate = useNavigate();
  const getPath = useGetPath();
  const { logout } = useLogout();

  const goToHomePage = () => {
    resetError && resetError();
    navigate(getPath('Login'));
  };

  const goToLoginPage = () => {
    resetError && resetError();
    logout();
    navigate(getPath('Login'));
  };

  const refresh = () => {
    resetError && resetError();
    navigate(0);
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh' data-testid='error-page'>
      <Stack justifyContent='center' alignItems='center' spacing={6}>
        <Typography variant='h1' color={theme.palette.primary.main}>
          Oops, Something Went Wrong!
        </Typography>
        <Typography variant='body1' color={theme.palette.primary.main} data-testid='error-message'>
          {error.toString()}
        </Typography>
        <Stack direction='row' spacing={2}>
          <Button onClick={refresh} data-testid='refresh-button'>
            Refresh
          </Button>
          <Button onClick={goToHomePage} variant='outlined' data-testid='go-to-home-button'>
            Go To Home Page
          </Button>
          <Button onClick={goToLoginPage} variant='outlined' data-testid='sign-out-button'>
            Sign Out
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
