interface WellnecityColorScales {
  75?: string;
  50?: string;
  25?: string;
  '01'?: string;
  '02'?: string;
  '03'?: string;
  '04'?: string;
  '05'?: string;
  '06'?: string;
  '07'?: string;
  '08'?: string;
  '09'?: string;
  '10'?: string;
}

declare module '@mui/material/styles' {
  interface WellnecityPalette {
    brand: {
      purple: Palette['primary'];
      orange: Palette['primary'];
    };
    application: {
      purple: Palette['primary'];
      cream: Palette['primary'];
      background: Palette['primary'];
      body: Palette['primary'];
      link: Palette['primary'];
    };
    neutral: Palette['primary'];
    feedback: {
      info01: Palette['primary'];
      info03: Palette['primary'];
      error: Palette['primary'];
    };
  }
  interface Palette extends WellnecityPalette {}

  interface PaletteOptions extends WellnecityPalette {}

  interface PaletteColor extends WellnecityColorScales {}

  interface SimplePaletteColorOptions extends WellnecityColorScales {}
}

export const wellnecityColors = {
  brand: {
    purple: {
      100: '#58539D',
      75: '#827EB5',
      50: '#ABA9CE',
      25: '#D5D4E7',
      // Aliases
      light: '#D5D4E7',
      main: '#58539D',
      dark: '#58539D',
      contrastText: '#FFFFFF',
    },
    orange: {
      100: '#E79E28',
      75: '#EDB75E',
      50: '#F3CE93',
      25: '#F9E7C9',
      // Aliases
      light: '#F9E7C9',
      main: '#E79E28',
      dark: '#E79E28',
      contrastText: '#000000',
    },
  },
  application: {
    purple: {
      100: '#3F3E66',
      75: '#5F5F7F',
      50: '#7F7E97',
      25: '#9F9FB1',
      trans10: '#58539D10',
      // Aliases
      light: '#9F9FB1',
      main: '#3F3E66',
      dark: '#3F3E66',
      contrastText: '#FFFFFF',
    },
    cream: {
      light: '#FBF9F4',
      main: '#FBF9F4',
      dark: '#FBF9F4',
      contrastText: '#FFFFFF',
    },
    background: {
      light: '#F2F3F8',
      main: '#F2F3F8',
      dark: '#F2F3F8',
      contrastText: '#FFFFFF',
    },
    body: {
      lighter: '#999999',
      light: '#484A4C',
      main: '#303030',
      dark: '#303030',
      contrastText: '#FFFFFF',
    },
    link: {
      light: '#0C55B8',
      main: '#0C55B8',
      dark: '#0C55B8',
      contrastText: '#FFFFFF',
    },
  },
  neutral: {
    '01': '#FFFFFF',
    '02': '#FAFAFA',
    '03': '#F2F2F2',
    '04': '#E5E5E6',
    '05': '#CBCCCD',
    '06': '#929496',
    '07': '#6E7072',
    '08': '#4B4D4F',
    '09': '#323334',
    '10': '#19191A',
    // Aliases
    light: '#E5E5E6',
    main: '#CBCCCD',
    dark: '#929496',
    contrastText: '#FFFFFF',
  },
  feedback: {
    info01: {
      light: '#E5F8FB',
      main: '#0052CC',
      dark: '#002677',
      contrastText: '#FBF9F4',
    },
    info03: {
      light: '#CDF7FF',
      main: '#008CB9',
      dark: '#036382',
      contrastText: '#FBF9F4',
    },
    error: {
      light: '#FCF0F0',
      main: '#C40000',
      dark: '#981228',
      contrastText: '#FBF9F4',
    },
    success: {
      light: '#EFF6EF',
      main: '#007000',
      dark: '#00573A',
      contrastText: '#FBF9F4',
    },
    warning: {
      light: '#FEF9EA',
      main: '#F5B700',
      dark: '#C37111',
      contrastText: '#19191A',
    },
  },
};

export const palette = {
  ...wellnecityColors,
  // MUI
  primary: {
    ...wellnecityColors.application.purple,
  },
  text: {
    primary: wellnecityColors.application.body.main,
    secondary: wellnecityColors.application.body.light,
  },
  info: {
    ...wellnecityColors.feedback.info01,
  },
  error: {
    ...wellnecityColors.feedback.error,
  },
  success: {
    ...wellnecityColors.feedback.success,
  },
  warning: {
    ...wellnecityColors.feedback.warning,
  },
  grey: {
    50: wellnecityColors.neutral['01'],
    100: wellnecityColors.neutral['02'],
    200: wellnecityColors.neutral['03'],
    300: wellnecityColors.neutral['04'],
    400: wellnecityColors.neutral['05'],
    500: wellnecityColors.neutral['06'],
    600: wellnecityColors.neutral['07'],
    700: wellnecityColors.neutral['08'],
    800: wellnecityColors.neutral['09'],
    900: wellnecityColors.neutral['10'],
  },
};
