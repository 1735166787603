import { useLogin } from '@/core/hooks';
import { useServices } from '@/core/services';
import { Organization } from '@/model/organization';
import { useGetPath } from '@/navigation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertColor, Box, Button, Stack, Typography } from '@mui/material';
import { FormButton, FormTextField } from '@wnc/ui/src/components/form';
import { DebugVersion } from '@wnc/ui/src/components/layout/debug-version';
import { LoginSearchPramas } from '@wnc/ui/src/constants';
import Yup from '@wnc/ui/src/utils';
import { values } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

const loginSchema = Yup.object({
  email: Yup.string().required().email().label('Email'),
  password: Yup.string().required().label('Password'),
});

type LoginFormValues = Yup.InferType<typeof loginSchema>;

const defaultValues: LoginFormValues = {
  email: '',
  password: '',
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();
  const { config } = useServices();
  const [searchParams] = useSearchParams();
  const severityParam = searchParams.get(LoginSearchPramas.Severity);
  const messageParam = searchParams.get(LoginSearchPramas.Message);
  const urlParam = searchParams.get(LoginSearchPramas.Url);
  const decodedUrl = urlParam && decodeURIComponent(urlParam);
  const token = localStorage.getItem('token');
  const env = config.env;
  const buildTimeStamp = process.env.REACT_APP_BUILD_TIMESTAMP;
  const version = process.env.REACT_APP_VERSION;
  const gitCommitHash = process.env.REACT_APP_GIT_COMMIT_HASH;

  const containsSearchParams = (urlParam: string | null) => {
    if (urlParam) {
      const queryString = urlParam.split('?')[1];
      return !!queryString;
    }
    return false;
  };

  if (token) {
    localStorage.removeItem('token');
  }

  const onGoToForgotPassword = () => {
    navigate(getPath('ForgotPassword'));
  };

  const { login, isLoading, isSuccess, isError, error, data } = useLogin();

  const formMethods = useForm<LoginFormValues>({
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    login(data);
  };

  if (isSuccess) {
    const token = data?.token;
    const hasPmmInZoe = (organization: Organization): boolean => {
      return organization.applications['zoe'] && organization.applications['zoe']['pmm'] !== undefined;
    };
    const hasRoles = (organization: Organization): boolean => {
      return !!organization.applications['zoe']['pmm']['roles'].length;
    };
    const organizations = values(data?.data.organizations);
    const organizationsWithPmmInZoe = organizations.filter((org) => hasPmmInZoe(org) && hasRoles(org));

    if (decodedUrl) {
      containsSearchParams(decodedUrl)
        ? window.location.replace(`${decodedUrl}&token=${encodeURIComponent(token)}`)
        : window.location.replace(`${decodedUrl}?token=${encodeURIComponent(token)}`);
    } else {
      if (organizationsWithPmmInZoe.length) {
        window.location.replace(`${config.pmmUrl}/?token=${encodeURIComponent(token)}`);
      } else {
        window.location.replace(`${config.pmiUrl}/?token=${encodeURIComponent(token)}`);
      }
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} data-testid='login-form'>
        <Stack spacing={3} alignItems='center'>
          <Typography variant='h2'>Welcome Back!</Typography>
          {isError && (
            <Alert severity='error' data-testid='error-message'>
              {error?.message}
            </Alert>
          )}
          {messageParam && (
            <Alert
              severity={severityParam ? (severityParam as AlertColor) : 'warning'}
              data-testid='inactivity-message'
            >
              {messageParam}
            </Alert>
          )}
          <FormTextField name='email' label='Email' placeholder='Your Email' required />
          <FormTextField
            name='password'
            label='Password'
            placeholder='Your Password'
            required
            type='password'
            autoComplete='password'
          />
        </Stack>
        <Stack spacing={1.5} mt={4} alignItems='center'>
          <FormButton fullWidth type='submit' disabled={isLoading} isLoading={isLoading} data-testid='sign-in-button'>
            Sign in
          </FormButton>
          <Button variant='text' size='small' onClick={onGoToForgotPassword} data-testid='forgot-password-button'>
            Forgot Password?
          </Button>
        </Stack>
        <Box
          justifySelf='flex-end'
          px={1}
          py={1}
          position={'absolute'}
          bottom={0}
          left={0}
          display='flex'
          justifyContent='center'
        >
          <DebugVersion env={env} buildTimeStamp={buildTimeStamp} version={version} gitCommitHash={gitCommitHash} />
        </Box>
      </form>
    </FormProvider>
  );
};
