import { Components, Theme } from '@mui/material';

export const MuiTimelineItem: Components<Omit<Theme, 'components'>>['MuiTimelineItem'] = {
  styleOverrides: {
    positionRight: {
      '&:before': {
        content: 'none',
      },
    },
  },
};
