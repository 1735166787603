import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Omit<Theme, 'components'>>['MuiAvatar'] = {
  defaultProps: {
    sizes: 'lg',
  },
  variants: [
    {
      props: { sizes: 'sm' },
      style: {
        width: '24px',
        height: '24px',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    {
      props: { sizes: 'md' },
      style: {
        width: '32px',
        height: '32px',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    {
      props: { sizes: 'lg' },
      style: {
        width: '44px',
        height: '44px',
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    {
      props: { sizes: 'xl' },
      style: {
        width: '56px',
        height: '56px',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.brand.purple.main}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.application.cream.main,
      fontWeight: 'bold',
    }),
  },
};
