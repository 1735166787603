import { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Omit<Theme, 'components'>>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.neutral['01'],
      color: theme.palette.text.primary,
      boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
    }),
  },
};
