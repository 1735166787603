import { createTheme } from '@mui/material/styles';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';
import type {} from '@mui/lab/themeAugmentation';

export const theme = createTheme({
  palette,
  typography,
  components,
});
