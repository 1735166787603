import { Components, Theme } from '@mui/material';

export const MuiListItemButton: Components<Omit<Theme, 'components'>>['MuiListItemButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.active': {
        backgroundColor: theme.palette.brand.purple[75],
        color: theme.palette.neutral['01'],
        dropShadow: '3px 0px 3px rgba(0, 0, 0, 0.25)',
        '.MuiListItemText-primary': { color: theme.palette.neutral['01'] },
      },
      '&:hover:not(.active)': {
        backgroundColor: theme.palette.neutral['02'],
        '.MuiListItemText-primary': { fontWeight: theme.typography.fontWeightMedium },
      },
    }),
  },
};
