import { AppContainer } from '@/components/app-container';
import { ErrorPage } from '@/pages/error';
import * as Sentry from '@sentry/react';
import { Navigate } from 'react-router-dom';
import { CustomRouteObject } from '../types';
import { externalRoutes } from './external-routes';

export const createRoutes = (): CustomRouteObject[] => {
  return [
    {
      element: (
        <Sentry.ErrorBoundary fallback={({ error, resetError }) => <ErrorPage error={error} resetError={resetError} />}>
          <AppContainer />
        </Sentry.ErrorBoundary>
      ),
      children: [
        ...externalRoutes,
        {
          id: 'Home',
          path: '/',
          element: <Navigate to='/login' />,
        },
      ],
    },
  ];
};
