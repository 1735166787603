import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { forEach, startCase } from 'lodash';
import { pmiApiUrls } from '../pmi-api-urls';
import * as authMockFunctions from './auth';
import { MockFns } from './types';

const mockFunctions: MockFns = {
  ...authMockFunctions,
};

export const createMock = (axiosInstance: AxiosInstance) => {
  const mock = new MockAdapter(axiosInstance, {
    delayResponse: 300,
  });

  forEach(pmiApiUrls, (apiUrl) => {
    if (apiUrl.mockKey) {
      const mockFunction = (mockFunctions as any)[apiUrl.mockKey];

      if (mockFunction) {
        const urlRegex = new RegExp(`^${apiUrl.url.replace(/:[a-zA-Z]+/g, '[a-zA-Z0-9\\-]+')}$`);
        (mock as any)[`on${startCase(apiUrl.method)}`](urlRegex).reply(mockFunction);
      }
    }
  });

  return mock;
};
