interface ApiUrlConfig {
  [x: string]: any;
  url: string;
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  mockKey?: string;
}

export const pmiApiUrls: Record<string, ApiUrlConfig> = {
  login: {
    url: '/auth/login',
    method: 'post',
    mockKey: 'loginMock',
  },
  logout: {
    url: '/auth/log_out',
    method: 'put',
    mockKey: 'logoutMock',
  },
  forgotPassword: {
    url: '/auth/forgot_password',
    method: 'post',
    mockKey: 'forgotPasswordMock',
  },
  resetPassword: {
    url: '/auth/reset_password',
    method: 'post',
    mockKey: 'resetPasswordMock',
  },
  getIdentity: {
    url: '/auth/me',
    method: 'get',
    mockKey: 'identityMock',
  },
};

export type ApiUrls = typeof pmiApiUrls;
