import { ThemeOptions } from '@mui/material/styles';
import { MuiAccordion } from './accordion';
import { MuiAccordionDetails } from './accordion-details';
import { MuiAccordionSummary } from './accordion-summary';
import { MuiAlert } from './alert';
import { MuiAppBar } from './app-bar';
import { MuiAutocomplete } from './autocomplete';
import { MuiAvatar } from './avatar';
import { MuiButton } from './button';
import { MuiCard } from './card';
import { MuiCardHeader } from './card-header';
import { MuiCheckbox } from './checkbox';
import { MuiChip } from './chip';
import { MuiCssBaseline } from './css-baseline';
import { MuiDrawer } from './drawer';
import { MuiFormControl } from './form-control';
import { MuiFormHelperText } from './form-helper-text';
import { MuiFormLabel } from './form-label';
import { MuiInput } from './input';
import { MuiInputAdornment } from './input-adornment';
import { MuiInputLabel } from './input-label';
import { MuiLinearProgress } from './linear-progress';
import { MuiLink } from './link';
import { MuiListItemButton } from './list-item-button';
import { MuiListItemText } from './list-item-text';
import { MuiSelect } from './select';
import { MuiTab } from './tab';
import { MuiTableCell } from './table-cell';
import { MuiTabs } from './tabs';
import { MuiTextField } from './text-field';
import { MuiTimelineItem } from './timeline-item';
import { MuiToggleButton } from './toggle-button';

export const components: ThemeOptions['components'] = {
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAccordion,
  MuiAlert,
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiButton,
  MuiLinearProgress,
  MuiCardHeader,
  MuiCard,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiDrawer,
  MuiFormControl,
  MuiFormHelperText,
  MuiFormLabel,
  MuiInputAdornment,
  MuiInputLabel,
  MuiInput,
  MuiLink,
  MuiListItemButton,
  MuiListItemText,
  MuiSelect,
  MuiTab,
  MuiTableCell,
  MuiTabs,
  MuiTextField,
  MuiTimelineItem,
  MuiToggleButton,
};
