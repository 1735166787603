import { palette } from './palette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export const typography = {
  fontFamily: '"Roboto", sans-serif',
  h1: {
    fontSize: '2.25rem',
    lineHeight: '3rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  h2: {
    fontSize: '2rem',
    lineHeight: '2.25rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  h3: {
    fontSize: '1.75rem',
    lineHeight: '2rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  h4: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  h5: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  h6: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
    fontWeight: 'bold',
    color: palette.application.purple.main,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
  },
  body2: {
    fontSize: '.875rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
  },
  body3: {
    fontSize: '.75rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
  },
};
