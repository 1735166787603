import { useForgotPassword } from '@/core/hooks';
import { useGetPath } from '@/navigation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { FormButton, FormTextField } from '@wnc/ui/src/components/form';
import Yup from '@wnc/ui/src/utils';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const forgotPasswordFormSchema = Yup.object({
  email: Yup.string().email().required().label('Email'),
});

type ForgotPasswordFormValues = Yup.InferType<typeof forgotPasswordFormSchema>;

const defaultValues: ForgotPasswordFormValues = {
  email: '',
};

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();
  const { forgotPassword, isLoading, isSuccess, isError, error } = useForgotPassword();

  const formMethods = useForm<ForgotPasswordFormValues>({
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = (data) => {
    forgotPassword(data);
  };

  const onGoToLogin = () => {
    navigate(getPath('Login'));
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} data-testid='forgot-password-form'>
        <Stack spacing={3} alignItems='center'>
          <Typography variant='h2'>Forgot Password?</Typography>
          {isError && (
            <Alert severity='error' data-testid='error-message'>
              {error?.message}
            </Alert>
          )}
          {isSuccess && (
            <Alert severity='success' data-testid='Password-reset-instructions-emailed'>
              An email has been sent with instructions for resetting your password.
            </Alert>
          )}
          <FormTextField name='email' label='Email' placeholder='Your Email' required />
        </Stack>
        <Stack spacing={1.5} mt={4} alignItems='center'>
          <FormButton
            fullWidth
            type='submit'
            disabled={isLoading}
            isLoading={isLoading}
            data-testid='send-email-button'
          >
            Send Email
          </FormButton>
          <Button variant='text' size='small' onClick={onGoToLogin} data-testid='back-to-login-button'>
            Back to Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
