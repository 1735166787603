import { createRoutes } from './routes/routes';
import { CustomRouteObject, PathMatch } from './types';

let allRoutes: CustomRouteObject[] | null = null;

const createOrGetRoutes = (): CustomRouteObject[] => {
  if (allRoutes === null) {
    allRoutes = createRoutes();
  }
  return allRoutes;
};

export const findRouteById = (
  id: string,
  routes: CustomRouteObject[] = createOrGetRoutes()
): CustomRouteObject | null => {
  for (let route of routes) {
    if (route.id === id) {
      return route;
    } else if (route.children) {
      const foundChild = findRouteById(id, route.children);
      if (foundChild) {
        return foundChild;
      }
    }
  }
  return null;
};

export const getTitle = (match: PathMatch): string | undefined => {
  const { id } = match;
  const foundRoute = findRouteById(id);
  return foundRoute?.title;
};
