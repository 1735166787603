import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-checked': {
        color: theme.palette.brand.purple.main,
      },
      '& .MuiSvgIcon-root': { fontSize: 26 },
    }),
  },
};
