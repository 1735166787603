import { useServices } from '@/core/services';
import { ResetPasswordPayload } from '@/core/services/api/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useResetPassword = () => {
  const { authApi: api } = useServices();

  const resetPasswordMutation = useMutation<{}, AxiosError, ResetPasswordPayload>({
    mutationKey: ['reset-password'],
    mutationFn: (data: ResetPasswordPayload) =>
      api.resetPassword(data).then((res) => {
        return res.data;
      }),
    // onSuccess: (data) => {},
  });

  return { ...resetPasswordMutation, resetPassword: resetPasswordMutation.mutate };
};
