import { useMemo } from 'react';
import { RouterProvider as ReactRouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { createRoutes } from './routes';

export const RouterProvider = () => {
  if (window.location.pathname === '/') {
    window.location.assign(process.env.PUBLIC_URL);
  }
  const routes = useMemo(() => createRoutes(), []);
  const router = useMemo(() => createBrowserRouter(routes, { basename: process.env.PUBLIC_URL }), [routes]);
  return <ReactRouterProvider router={router} />;
};
