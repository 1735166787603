import { Components, Theme } from '@mui/material';

export const MuiAccordionSummary: Components<Omit<Theme, 'components'>>['MuiAccordionSummary'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 'unset',
      fontWeight: theme.typography.fontWeightMedium,
      '&.Mui-expanded': {
        minHeight: 'unset',
      },
    }),
    content: {
      margin: '0.75rem 0',
      '&.Mui-expanded': {
        margin: '0.75rem 0',
      },
    },
  },
};
