import { IdentityRole, Organization } from '@/model/organization';
import { getLogo } from '@wnc/ui/src/model';
import { random } from 'lodash';

const minDollars = 300000.0;
const maxDolsars = 800000.0;
const totalManaged = random(minDollars, maxDolsars);

export const chc: Organization = {
  id: 'org-001',
  name: 'CHC',
  logoUrl: getLogo('connect-healthcare-collaboration.png'),
  applications: {
    zoe: {
      pmi: {
        clients: {},
        practitioner_id: 'practitioner-001',
        roles: [IdentityRole.Nurse],
      },
      pmm: {
        clients: {},
        practitioner_id: 'practitioner-001',
        roles: [IdentityRole.SuperAdmin],
      },
    },
  },
  totalManaged,
};

export const wellnecity: Organization = {
  id: 'org-002',
  name: 'Wellnecity',
  logoUrl: getLogo('flow.png'),
  applications: {
    zoe: {
      pmi: {
        clients: {},
        practitioner_id: 'practitioner-001',
        roles: [IdentityRole.Nurse],
      },
      pmm: {
        clients: {},
        practitioner_id: 'practitioner-001',
        roles: [IdentityRole.User],
      },
    },
  },
  totalManaged,
};
