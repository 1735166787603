import { Client } from './client';

export enum IdentityRole {
  SuperAdmin = 'Super Admin',
  User = 'User',
  Nurse = 'Nurse',
}

export interface Organization {
  id: string;
  name: string;
  applications: {
    [k: string]: {
      [k: string]: {
        clients: {
          [k: string]: Client;
        };
        practitioner_id: string;
        roles: IdentityRole[];
      };
    };
  };
  logoUrl?: string;
  totalManaged?: number;
}
